import React, { Component } from 'react';
import { API_URL } from '../constants/constants';
import { Image, Dimmer, Loader } from 'semantic-ui-react';
import Search from '../components/common/search';
import SearchResults from '../components/searchResults/index';
import Axios from 'axios';
import Helmet from 'react-helmet';
import { toast } from 'react-toastify';
import '../components/style.less';
import { connect } from 'react-redux';
import { storeSearchResults, storeDocCount, storeLastSearchQuery } from '../js/actions/index';

class IndexPage extends Component {
  state = {
    loading: true,
    jobs: [],
    userId: undefined,
    hasSearched: false
  };

  componentDidMount() {
    if (this.props.searchResults) {
      this.setState({
        jobs: this.props.searchResults,
        hasSearched: true
      });
    }
    Axios.get(`${API_URL}/countJobs`).then(response => {
      this.setState({ jobCount: response.data.count, loading: false });
    });
  }

  onSearch = (event, query) => {
    this.setState({ loading: true, hasSearched: true });
    event.preventDefault();
    if (query !== '') {
      this.setState({ loading: true, jobs: [] });
      Axios.get(`${API_URL}/search?param=` + query).then(response => {
        if (response.data.success) {
          this.props.storeSearchResults(response.data.response);
          this.props.storeDocCount(response.data.response.length);
          this.props.storeLastSearchQuery(query);
          this.setState({
            jobs: response.data.response,
            loading: false,
            hasSearched: true
          });
        } else {
          this.setState({ loading: false });
        }
      });
    } else {
      toast.warn('Try searching for a city, country, or keyword');
      this.setState({ loading: false });
    }
  };

  viewLatest = () => {
    this.setState({ loading: true });
    Axios.get(`${API_URL}/get-recent-jobs`, { headers: { origin: 'https://www.eslbot.com' } })
      .then(data => {
        this.props.storeSearchResults(data.data.response);
        this.props.storeDocCount(data.data.response.length);
        this.setState({
          jobs: data.data.response,
          loading: false,
          hasSearched: true
        });
      })
      .catch(error => toast.error(error));
  };

  render() {
    return (
      <>
        <div>
          <Helmet
            title='eslbot'
            meta={[
              {
                name: 'description',
                content: 'We have the largest collection of ESL, TEFL, and TESOL jobs, period. Search, post, and apply.'
              },
              { name: 'keywords', content: 'tesol tefl esl teaching teach jobs china japan vietnam korea thailand post apply' },
              ,
              {
                name: 'viewport',
                content: 'width=device-width, initial-scale=1, shrink-to-fit=no, viewport-fit=cover'
              }
            ]}
          />
        </div>
        <div style={{ textAlign: 'center' }}>
          <Image
            className='home-splash-image'
            wrapped
            size='tiny'
            src='https://res.cloudinary.com/asdfbot/image/upload/c_scale,h_80,w_80/v1582510548/icon_hbzo0k.png'
          />
        </div>
        {!this.state.hasSearched ? (
          <div style={{ textAlign: 'center', marginBottom: 20 }}>
            <p>
              the largest collection of ESL, TESOL, and TEFL jobs on the web
              <br />
              <br />
              search, post, and apply for jobs in Japan, South Korea, China, Thailand, and everywhere else
            </p>
          </div>
        ) : null}
        <Search onSearch={this.onSearch} lastSearch={this.props.lastSearchQuery} />
        {!this.state.hasSearched ? (
          <div style={{ textAlign: 'center', marginTop: 10 }}>
            <span>{this.state.jobCount} jobs posted in the last 60 days</span>.{' '}
            <a onClick={this.viewLatest} style={{ cursor: 'pointer' }}>
              view latest
            </a>
          </div>
        ) : null}
        {!this.state.loading && this.state.hasSearched ? <SearchResults results={this.state.jobs} /> : null}
        <Dimmer inverted active={this.state.loading}>
          <Loader content='loading jobs' />
        </Dimmer>
      </>
    );
  }
}

const mapStateToProps = state => {
  return { searchResults: state.searchResults, docCount: state.docCount, lastSearchQuery: state.lastSearchQuery };
};

const mapDispatchToProps = dispatch => {
  return {
    storeSearchResults: jobs => dispatch(storeSearchResults(jobs)),
    storeDocCount: docCount => dispatch(storeDocCount(docCount)),
    storeLastSearchQuery: lastSearchResults => dispatch(storeLastSearchQuery(lastSearchResults))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IndexPage);
