import React from 'react';
import InternalResult from './internalResult';
import ExternalResult from './externalResult';

const SearchResults = props => {
  const jobs = props.results;
  return (
    <div className='results'>
      <p style={{ textAlign: 'center' }}>
        {jobs.length === 50
          ? `you're viewing the latest 50 jobs`
          : jobs.length === 0
          ? 'we found no jobs. try searching again'
          : `we found ${jobs.length} jobs`}
      </p>
      {jobs.map((job, index) => {
        return !job.hasOwnProperty('externalPosting') ? <ExternalResult job={job} key={index} /> : <InternalResult job={job} key={index} />;
      })}
    </div>
  );
};

export default SearchResults;
