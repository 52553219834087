import { Button, Form } from 'semantic-ui-react';
import React from 'react';

class Search extends React.Component {
  state = {
    query: ''
  };

  componentDidMount() {
    if (this.props.lastSearch) {
      this.setState({ query: this.props.lastSearch})
    }
  }
  
  render() {
    return (
      <div className='main'>
        <Form style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Form.Input
            style={{ display: 'flex', width: '220px', marginRight: '5px', position: 'relative', top: '1px' }}
            value={this.state.query}
            icon='globe'
            iconPosition='left'
            placeholder='keyword, city, or country'
            onChange={event => this.setState({ query: event.target ? event.target.value : '' })}
            type='text'
          />
          <Button
            primary
            style={{ position: 'relative', top: '-6px', height: '38px' }}
            onClick={event => this.props.onSearch(event, this.state.query)}
          >
            search
          </Button>
        </Form>
      </div>
    );
  }
}


export default Search
