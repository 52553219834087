import React from 'react';
import { Link } from 'gatsby';
import { Flag, Icon, Divider } from 'semantic-ui-react';
import Moment from 'react-moment';
import 'moment-timezone';

const InternalResult = props => {
  return (
    <div className='internal-result'>
      <div className='row'>
        <div className='column'>
          <Link className='search-result-job-link' to={`/job?jobId=${props.job._id}`}>
            {props.job.jobTitle}
          </Link>
        </div>
      </div>
      <div className='row'>
        <div className='column'>
          <p>
            <Flag name={props.job.country.toLowerCase()} />
            {props.job.city}, {props.job.country}
            <br />
            <Icon name='briefcase' /> {props.job.name}
            <br />
            <Icon name='calendar' />
            <Moment fromNow date={props.job.updatedAt} />
          </p>
        </div>
      </div>
      <Divider />
    </div>
  );
};

export default InternalResult;
