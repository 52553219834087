import { Flag, Icon, Divider } from 'semantic-ui-react';
import React from 'react';
import Moment from 'react-moment';
import 'moment-timezone';

const ExternalResult = props => {
  return (
    <div className='external-result'>
      <a className='headline' href={props.job.link} rel='noopener' target='_blank'>
        {props.job.text
          ? props.job.text.replace(
              /([\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
              ''
            )
          : null}
      </a>
      <div className='row'>
        <div className='column'>
          <p className='details'>
            {/* 
      // @ts-ignore */}
            <Flag name={props.job.location.toLowerCase()} />
            {props.job.location}
            <br />
            <a className='site-link' href={props.job.websiteLink} rel='noopener' target='_blank'>
              <Icon name='at' />
              {props.job.website}
            </a>
            <br />
            <Icon name='calendar' />
            <Moment fromNow date={props.job.updatedAt} />
          </p>
        </div>
      </div>
      <Divider />
    </div>
  );
};

export default ExternalResult;
